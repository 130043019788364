<script lang="ts">
	import { isMenuActive } from "../../utils/isMenuActive.js";
	import fb from "../../../../core/assets/icons/fb.svg?raw";
	import ig from "../../../../core/assets/icons/ig.svg?raw";
	import Icon from "../Icon.svelte";
	import Link from "../Link.svelte";
	import { createEventDispatcher, getContext } from "svelte";
	import type { MenuItem } from "../../../../core/schema/MenuItem.js";
	import { pageSlugContextKey } from "../../contexts/pageSlugContextKey.js";

	export let menu: MenuItem[];

	const pageSlug = getContext<string>(pageSlugContextKey);

	const dispatch = createEventDispatcher<{
		click: MenuItem;
	}>();

	const menuItemLiClasses =
		"menu-item lg:[&:hover>div>.menu-link]:bg-theme lg:[&:hover>div>.menu-link]:text-white lg:[&:hover_.navigation]:pointer-events-auto lg:[&:hover_.navigation]:opacity-100";
	const menuItemLinkClasses =
		"lg:whitespace-nowrap lg:py-[0.35rem] lg:text-[.95rem] xl:text-base uppercase font-semibold px-3 leading-[1.25] 2xl:py-2";

	// eslint-disable-next-line no-warning-comments
	// FIXME remove this after upgrade to Svelte 5.
	/* eslint-disable @typescript-eslint/explicit-function-return-type */
</script>

<div
	class="mt-8 flex grow justify-center overflow-y-auto overflow-x-hidden lg:mt-0 lg:h-full lg:w-auto lg:justify-end lg:overflow-y-hidden"
>
	<ul class="flex flex-col items-center gap-8 lg:flex-row lg:justify-center lg:gap-0">
		<li class="sm:text-2xs text-[0.6rem] font-semibold uppercase leading-4 tracking-[0.15rem] lg:hidden">Menu</li>
		{#each menu as menuItem}
			<li class={menuItemLiClasses}>
				<div class="group relative w-full">
					<Link
						noUnderline
						class="{menuItemLinkClasses} hover:text-primary transition-colors duration-300 {isMenuActive(
							menuItem,
							pageSlug,
						)
							? 'underline !decoration-black underline-offset-4'
							: ''}"
						href={`/${menuItem.href}`}
						target={menuItem.target}
						on:click={() => {
							dispatch("click", menuItem);
						}}
					>
						{menuItem.name}
					</Link>
				</div>
			</li>
		{/each}
		<li class="flex justify-between gap-3 pb-4 lg:px-3 lg:pb-0">
			<Link href="https://www.facebook.com/sladkadilna" target="_blank">
				<Icon icon={fb} class="hover:text-primary h-[1.25rem] transition-colors duration-300" />
			</Link>
			<Link href="https://www.instagram.com/sladkadilna?igsh=MXRhNDJ4M295b2F1Mg%3D%3D&utm_source=qr" target="_blank">
				<Icon icon={ig} class="hover:text-primary h-[1.25rem] transition-colors duration-300" />
			</Link>
		</li>
	</ul>
</div>
