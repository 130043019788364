import { Locale, type LocalizedString } from "./Locale.js";

export enum PaymentType {
	GoPayPaymentCard = "GoPayPaymentCard",
	GoPayGPay = "GoPayGPay",
	GoPayApplePay = "GoPayApplePay",
	OnDelivery = "OnDelivery",
	// GoPayBankAccount = "GoPayBankAccount",
}

export const paymentTypeNameMap: Record<PaymentType, LocalizedString> = {
	[PaymentType.GoPayPaymentCard]: { [Locale.cs]: "Kartou" },
	[PaymentType.GoPayGPay]: { [Locale.cs]: "Google Pay" },
	[PaymentType.GoPayApplePay]: { [Locale.cs]: "Apple Pay" },
	[PaymentType.OnDelivery]: { [Locale.cs]: "Dobírkou" },
	// [PaymentType.GoPayBankAccount]: {[Locale.cs]: "Bankovním převodem" },
};

export const paymentTypeLongNameMap: Record<PaymentType, LocalizedString> = {
	[PaymentType.GoPayPaymentCard]: { [Locale.cs]: "Platební kartou" },
	[PaymentType.GoPayGPay]: { [Locale.cs]: "Google Pay" },
	[PaymentType.GoPayApplePay]: { [Locale.cs]: "Apple Pay" },
	[PaymentType.OnDelivery]: { [Locale.cs]: "Platba na místě hotově nebo kartou" },
	// [PaymentType.GoPayBankAccount]: { [Locale.cs]: "Bankovním převodem" },
};
