import { Locale, type LocalizedString } from "./Locale.js";
import type { ProductPricesByLevel } from "./ProductPricesByLevel.js";

export enum ProductSize {
	Xs = "Xs",
	S = "S",
	M = "M",
	L = "L",
}

export const productSizeKeyMap = {
	[ProductSize.Xs]: "xs",
	[ProductSize.S]: "s",
	[ProductSize.M]: "m",
	[ProductSize.L]: "l",
} satisfies Record<ProductSize, Required<keyof ProductPricesByLevel>>;

export const productSizeKeyReversedMap = Object.fromEntries(
	Object.entries(productSizeKeyMap).map(([key, value]) => [value, key] as const),
) as {
	// eslint-disable-next-line no-use-before-define
	[Size in keyof typeof productSizeKeyMap as (typeof productSizeKeyMap)[Size]]: Size;
};

export const productSizeNameMap: Record<ProductSize, LocalizedString> = {
	[ProductSize.Xs]: {
		[Locale.cs]: "XS",
	},
	[ProductSize.S]: {
		[Locale.cs]: "S",
	},
	[ProductSize.M]: {
		[Locale.cs]: "M",
	},
	[ProductSize.L]: {
		[Locale.cs]: "L",
	},
};
