import { Locale, type LocalizedString } from "./Locale.js";

export enum OrderProductStatus {
	ToDo = "ToDo",
	Incomplete = "Incomplete",
	AssignedToConfectioner = "AssignedToConfectioner",
	Ready = "Ready",
}

export const orderProductStatusNameMap: Record<OrderProductStatus, LocalizedString> = {
	[OrderProductStatus.ToDo]: {
		[Locale.cs]: "TODO",
	},
	[OrderProductStatus.Incomplete]: {
		[Locale.cs]: "Rozděláno",
	},
	[OrderProductStatus.AssignedToConfectioner]: {
		[Locale.cs]: "Zadáno cukráři",
	},
	[OrderProductStatus.Ready]: {
		[Locale.cs]: "Vyrobeno",
	},
};
