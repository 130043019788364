import { doArraysContainSameNumbers } from "../../utils/doArraysContainSameNumbers.js";
import type { OrderProduct } from "../OrderProduct.js";
import type { ProductMacaroons } from "../ProductMacaroons.js";
import type { ProductPhoto } from "../ProductPhoto.js";

function isSamePhoto(first: ProductPhoto | undefined, second: ProductPhoto | undefined): boolean {
	if (first === undefined) {
		return second === undefined;
	}
	if (second === undefined) {
		return false;
	}

	if ("url" in first && "url" in second) {
		return first.url === second.url;
	}

	return first.file.path === second.file.path;
}

export function isSameProduct(first: OrderProduct, second: OrderProduct): boolean {
	const conditions = [first.product.id === second.product.id, Boolean(first.options) === Boolean(second.options)];

	const firstOptions = first.options;
	const secondOptions = second.options;
	if (firstOptions && secondOptions) {
		const conditionMap = {
			numbers: doArraysContainSameNumbers(
				firstOptions.numbers.map(({ number }) => number),
				secondOptions.numbers.map(({ number }) => number),
			),
			extraFruit: firstOptions.extraFruit === secondOptions.extraFruit,
			glutenFree: firstOptions.glutenFree === secondOptions.glutenFree,
			lactoseFree: firstOptions.lactoseFree === secondOptions.lactoseFree,
			size: firstOptions.size === secondOptions.size,
			meringues: firstOptions.meringues === secondOptions.meringues,
			creamColor: firstOptions.creamColor === secondOptions.creamColor,
			labels:
				firstOptions.labels.length === secondOptions.labels.length &&
				firstOptions.labels.every((label) => secondOptions.labels.some((otherLabel) => label.text === otherLabel.text)),
			lyingPhoto: isSamePhoto(firstOptions.lyingPhoto, secondOptions.lyingPhoto),
			standingPhotos:
				firstOptions.standingPhotos.length === secondOptions.standingPhotos.length &&
				firstOptions.standingPhotos.every((photo) =>
					secondOptions.standingPhotos.some((otherPhoto) => isSamePhoto(photo, otherPhoto)),
				),
			macaroons:
				(firstOptions.macaroons === undefined && secondOptions.macaroons === undefined) ||
				(firstOptions.macaroons !== undefined &&
					secondOptions.macaroons !== undefined &&
					Object.entries(firstOptions.macaroons).every(
						([key, value]) => secondOptions.macaroons?.[key as keyof ProductMacaroons] === value,
					)),
			figurines:
				firstOptions.figurines.length === secondOptions.figurines.length &&
				firstOptions.figurines.every((figurine) =>
					secondOptions.figurines.some(
						(otherFigurine) =>
							figurine.description === otherFigurine.description && figurine.price === otherFigurine.price,
					),
				),
		} satisfies Record<keyof NonNullable<OrderProduct["options"]>, boolean>;

		conditions.push(...Object.values(conditionMap));
	}
	return conditions.every(Boolean);
}
