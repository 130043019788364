import type { Price } from "../Price.js";
import type { ProductForCustomer } from "../Product.js";
import { productSizeKeyMap } from "../ProductSize.js";
import { defaultSize } from "../defaultSize.js";

export function getPriceForProductForCustomerOfSize(
	product: ProductForCustomer,
	size = defaultSize,
): Price | undefined {
	const sizeKey = productSizeKeyMap[size];
	return product.priceForCustomer[sizeKey];
}
