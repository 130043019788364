<script lang="ts">
	import type { MenuItem } from "../../../../core/schema/MenuItem";
	import Icon from "../Icon.svelte";
	import StaticPicture from "../Picture/StaticPicture.svelte";
	import { appName } from "../../../../core/schema/appName";
	import logo from "../../../../core/assets/logo.svg";
	import cross from "../../../../core/assets/icons/cross.svg?raw";
	import Menu from "./Menu.svelte";

	export let isOpen = false;
	export let menu: MenuItem[];

	function onClose(): void {
		isOpen = false;
	}

	function onClick({ detail }: CustomEvent<MenuItem>): void {
		if (detail.id === "kontakt") {
			isOpen = false;
		}
	}
</script>

<nav class="block {isOpen && 'fixed left-0 top-0 z-50 m-0 flex h-full w-full flex-col bg-white px-4 py-3'}">
	<div class="flex items-start justify-between">
		<a href="/" class="w-[4.5rem]">
			<StaticPicture
				alt="Logo {appName}"
				image={logo}
				class="w-[4.5rem] xl:hidden"
				loading="eager"
				width={72}
				height={72}
			/>
		</a>
		<button class="mt-1" on:click={onClose}>
			<span class="text-2xs flex items-center gap-1 font-semibold leading-5">
				Zavřít
				<Icon icon={cross} class="h-2 w-2" />
			</span>
		</button>
	</div>

	<Menu {menu} on:click={onClick} />
</nav>
