import { type Readable, get } from "svelte/store";
import type { AppliedVoucher } from "../../../../core/schema/AppliedVoucher.js";
import type { Order } from "../../../../core/schema/Order.js";
import type { OrderProduct } from "../../../../core/schema/OrderProduct.js";
import type { OrderProductOptions } from "../../../../core/schema/OrderProductOptions.js";
import type { OrderVoucher } from "../../../../core/schema/OrderVoucher.js";
import type { ProductForCustomer } from "../../../../core/schema/Product.js";
import type { VoucherProduct } from "../../../../core/schema/VoucherProduct.js";
import type { RequiredCategories } from "../../../../core/schema/namesOfRequiredCategories.js";
import { getPriceForProductForCustomerOfSize } from "../../../../core/schema/utils/getPriceForProductForCustomerOfSize.js";
import { isSameProduct } from "../../../../core/schema/utils/isSameProduct.js";
import { transformProductToOrderProduct } from "../../../../core/schema/utils/transformProductToOrderProduct.js";
import { transformVoucherProductToOrderVoucher } from "../../../../core/schema/utils/transformVoucherProductToOrderVoucher.js";
import type { DeepPartial } from "../../../../core/utils/DeepPartial.js";
import { deepMerge } from "../../../../core/utils/deepMerge.js";
import { type LocalStorageStoreValue, localStorageWritable } from "../../../../core/utils/localStorageWritable.js";
import type { AddResponse } from "./AddResponse.js";
import type { Cart, CartInStep } from "./Cart.js";
import { CartStep, cartStepOrderMap, getNextStep } from "./CartStep.js";
import { cartVersion } from "./cartVersion.js";
import { cartWithAppliedProperties } from "./cartWithAppliedProperties.js";
import { ensureCartIsInCorrectStep } from "./ensureCartIsInCorrectStep.js";
import type { FieldsToFillInPerStep } from "./fieldsToFillInPerStep.js";

const initialCart: Cart = cartWithAppliedProperties({
	productsInOrder: [],
	properties: {
		cantPayOnDeliveryReasons: [],
		isFree: false,
	},
	order: {
		products: [],
		vouchers: [],
	},
	step: CartStep.Overview,
});

const localStorageKey = `cart-${cartVersion}`;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type,@typescript-eslint/explicit-module-boundary-types
export function createCart(requiredCategories: RequiredCategories) {
	const store = localStorageWritable<Cart>(localStorageKey, initialCart);
	const { subscribe, set, update: _update } = store;

	/*
	 * Custom update function that applies properties to the cart and ensures that the cart is in the correct step (depending on the fields that must be filled in per step).
	 * Also provides a way to run a callback after the cart has been updated.
	 */
	async function update<T extends Cart>(updater: (value: T) => Promise<T> | T): Promise<T> {
		const current = get(store);
		const afterUpdate = await updater(current.value as T);
		return new Promise((resolve) => {
			_update(() => {
				const cartWithProperties = cartWithAppliedProperties(afterUpdate);
				const cartWithEnsuredCorrectStep = ensureCartIsInCorrectStep(cartWithProperties);

				resolve(cartWithEnsuredCorrectStep);
				return cartWithEnsuredCorrectStep;
			});
		});
	}

	async function removeProduct(productIndex: number): Promise<Cart> {
		return await update((cart) => {
			const orderProductToRemove = cart.order.products[productIndex];
			if (!orderProductToRemove) {
				throw new Error("Index is out of bounds");
			}

			cart.order.products.splice(productIndex, 1);

			const noMoreProductOfIdInCart = !cart.order.products.some(
				({ product: { id } }) => id === orderProductToRemove.product.id,
			);
			if (noMoreProductOfIdInCart) {
				cart.productsInOrder = cart.productsInOrder.filter(({ id }) => id !== orderProductToRemove.product.id);
			}

			if (cart.order.products.length === 0 && cart.order.vouchers.length === 0) {
				return initialCart;
			}

			if (!cart.order.products.some(({ product }) => product.categoryIds.includes(requiredCategories.fillings.id))) {
				cart.weddingFormId = undefined;
			}

			return cart;
		});
	}

	async function removeVoucher(voucherIndex: number): Promise<Cart> {
		return await update((cart) => {
			const orderVoucherToRemove = cart.order.vouchers[voucherIndex];
			if (!orderVoucherToRemove) {
				throw new Error("Index is out of bounds");
			}

			cart.order.vouchers.splice(voucherIndex, 1);
			if (cart.order.products.length === 0 && cart.order.vouchers.length === 0) {
				return initialCart;
			}

			return cart;
		});
	}

	async function setProductQuantity(productIndex: number, quantity: number): Promise<Cart> {
		return await update(async (cart) => {
			if (quantity > 0) {
				const orderProduct = cart.order.products[productIndex];
				if (!orderProduct) {
					throw new Error("Index is out of bounds");
				}
				orderProduct.quantity = quantity;

				return cart;
			} else {
				return await removeProduct(productIndex);
			}
		});
	}

	async function setVoucherQuantity(voucherIndex: number, quantity: number): Promise<Cart> {
		return await update(async (cart) => {
			if (quantity > 0) {
				const orderVoucher = cart.order.vouchers[voucherIndex];
				if (!orderVoucher) {
					throw new Error("Index is out of bounds");
				}
				orderVoucher.quantity = quantity;

				return cart;
			} else {
				return await removeVoucher(voucherIndex);
			}
		});
	}

	async function saveFields<CurrentCart extends Cart = Cart>(
		fields: DeepPartial<CurrentCart["order"]>,
	): Promise<CurrentCart> {
		return await update<CurrentCart>((cart) => {
			cart.order = deepMerge(cart.order, fields);
			return cart;
		});
	}

	return {
		subscribe,
		setProductQuantity,
		setVoucherQuantity,
		saveFields,
		async advanceToNextStep<Step extends CartStep>(
			currentStep: Step,
			fields: Pick<CartInStep<Step>["order"], FieldsToFillInPerStep<Step>>,
			onAdvance?: (cart: Cart) => void,
		): Promise<boolean> {
			await saveFields(fields);

			let advanced = false;
			let canAdvance = false;

			const updated = await update((cart) => {
				if (cart.order.products.length === 0 && cart.order.vouchers.length === 0) {
					return cart;
				}

				const cartStepIndex = cartStepOrderMap[cart.step];
				const nextStep = getNextStep(currentStep);
				const nextStepIndex = cartStepOrderMap[nextStep];
				if (cartStepIndex < nextStepIndex) {
					cart.step = nextStep;
					advanced = true;
				}

				canAdvance = true;
				return cart;
			});

			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
			if (advanced) {
				onAdvance?.(updated);
			}

			return canAdvance;
		},
		async increaseQuantity(productIndex: number): Promise<void> {
			await update((cart) => {
				const orderProduct = cart.order.products[productIndex];
				if (!orderProduct) {
					throw new Error("Index is out of bounds");
				}
				return setProductQuantity(productIndex, orderProduct.quantity + 1);
			});
		},
		async decreaseQuantity(productIndex: number): Promise<void> {
			await update((cart) => {
				const orderProduct = cart.order.products[productIndex];
				if (!orderProduct) {
					throw new Error("Index is out of bounds");
				}
				return setProductQuantity(productIndex, orderProduct.quantity - 1);
			});
		},
		async addProduct(
			product: ProductForCustomer,
			quantity: number,
			minimumQuantity = 1,
			options?: OrderProductOptions,
		): Promise<AddResponse<OrderProduct>> {
			let response = {} as AddResponse<OrderProduct>;
			await update((cart) => {
				const productToAdd = transformProductToOrderProduct(crypto.randomUUID(), product, quantity, options);
				const isFirstProductOfId = !cart.order.products.some(({ product: { id } }) => id === productToAdd.product.id);
				if (isFirstProductOfId) {
					cart.productsInOrder.push(product);
				}
				const existingProduct = cart.order.products.find((orderProduct) => isSameProduct(orderProduct, productToAdd));

				if (existingProduct) {
					const oldQuantity = existingProduct.quantity;
					existingProduct.quantity = Math.max(existingProduct.quantity + quantity, minimumQuantity);
					response = {
						type: "increment",
						item: productToAdd,
						quantity: existingProduct.quantity - oldQuantity,
					};
				} else {
					if (productToAdd.quantity < minimumQuantity) {
						productToAdd.quantity = minimumQuantity;
					}
					cart.order.products.push(productToAdd);
					response = {
						type: "add",
						item: productToAdd,
						quantity: productToAdd.quantity,
					};
				}

				const weddingFormId = localStorage.getItem("weddingFormId");
				if (weddingFormId && product.categoryIds.includes(requiredCategories.fillings.id)) {
					cart.weddingFormId = weddingFormId;
				}

				return cart;
			});
			return response;
		},
		async updateItemOptions(productIndex: number, optionsToUpdate: Partial<OrderProductOptions>): Promise<void> {
			await update((cart) => {
				const orderProduct = cart.order.products[productIndex];
				if (!orderProduct) {
					throw new Error("Index is out of bounds");
				}
				// Cakes always have options, other products never. So this should be safe.
				if (orderProduct.options) {
					orderProduct.options = {
						...orderProduct.options,
						...optionsToUpdate,
					};
				}

				const product = cart.productsInOrder.find(({ id }) => id === orderProduct.product.id);
				if (!product) {
					throw new Error("Detail for product in cart not found.");
				}
				const price = getPriceForProductForCustomerOfSize(product, orderProduct.options?.size);
				if (!price) {
					throw new Error("Price of product not found");
				}
				orderProduct.product.price = price;

				return cart;
			});
		},
		async updateItem(productIndex: number, product: ProductForCustomer): Promise<void> {
			await update((cart) => {
				const orderProduct = cart.order.products[productIndex];
				if (!orderProduct) {
					throw new Error("Index is out of bounds");
				}
				const productInOrderIndex = cart.productsInOrder.findIndex(({ id }) => id === product.id);
				if (productInOrderIndex === -1) {
					throw new Error("Product not found in productsInOrder");
				}
				cart.productsInOrder[productInOrderIndex] = product;
				cart.order.products[productIndex] = transformProductToOrderProduct(
					orderProduct.id,
					product,
					orderProduct.quantity,
					orderProduct.options,
				);

				return cart;
			});
		},
		async addVoucher(voucher: VoucherProduct): Promise<AddResponse<OrderVoucher>> {
			let response = {} as AddResponse<OrderVoucher>;

			await update((cart) => {
				const existingVoucher = cart.order.vouchers.find(
					(voucherInCart) => voucherInCart.orderVoucherProduct.id === voucher.id,
				);
				const voucherToAdd = transformVoucherProductToOrderVoucher(crypto.randomUUID(), voucher, 1);
				if (existingVoucher) {
					existingVoucher.quantity = existingVoucher.quantity + 1;
					response = {
						type: "increment",
						item: voucherToAdd,
						quantity: 1,
					};
				} else {
					cart.order.vouchers.push(voucherToAdd);
					response = {
						type: "add",
						item: voucherToAdd,
						quantity: 1,
					};
				}

				return cart;
			});
			return response;
		},
		async applyVoucher(voucher: AppliedVoucher, code: string): Promise<void> {
			await update((cart) => {
				cart.usedVoucherCode = code;
				cart.order.appliedVoucher = voucher;
				return cart;
			});
		},
		async unapplyVoucher(): Promise<void> {
			await update((cart) => {
				cart.usedVoucherCode = undefined;
				cart.order.appliedVoucher = undefined;
				return cart;
			});
		},
		async markAsSent(order: Order): Promise<Cart> {
			return await update((cart) => {
				if (cart.step !== CartStep.ReadyToSend) {
					throw new Error("The cart is not ready to be sent");
				}
				cart.sentOrder = order;
				return cart;
			});
		},
		removeProduct,
		removeVoucher,
		clear(): void {
			set(structuredClone(initialCart));
		},
		async clearDeliveryDate(): Promise<void> {
			await update((cart) => {
				cart.order.deliveryDate = undefined;
				return cart;
			});
		},
		async clearPickUpBranch(): Promise<void> {
			await update((cart) => {
				cart.order.pickUpBranch = undefined;
				return cart;
			});
		},
	} satisfies Readable<LocalStorageStoreValue<Cart>> & Record<string, unknown>;
}
