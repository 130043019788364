import { roundToPlaces } from "../../utils/roundToPlaces.js";
import type { B2bOrderCreateInput, B2cOrderCreateInput, Order } from "../Order.js";
import type { PriceLevel } from "../PriceLevel.js";
import { getPriceOfOrderProduct } from "./getPriceOfOrderProduct.js";
import { getSpreadedProducts } from "./getSpreadedProducts.js";
import { getSpreadedVouchers } from "./getSpreadedVouchers.js";

export function getPriceOfOrder(
	order: Pick<Order | B2cOrderCreateInput, "products" | "vouchers"> | Pick<B2bOrderCreateInput, "products">,
	priceLevel: PriceLevel,
): Order["price"] {
	let withTax = 0;
	let withoutTax = 0;

	/*
	 * Spread products before calculating the price, otherwise price is not calculated precisely.
	 * Random UUID is not important in this context.
	 */
	const spreadedProducts = getSpreadedProducts(order.products, () => "");
	const spreadedVouchers = getSpreadedVouchers("vouchers" in order ? order.vouchers : [], () => "");

	for (const product of spreadedProducts) {
		const { withTax: productWithTax, withoutTax: productWithoutTax } = getPriceOfOrderProduct(product);
		withTax += productWithTax;
		withoutTax += productWithoutTax;
	}

	for (const voucher of spreadedVouchers) {
		withTax += voucher.orderVoucherProduct.value * voucher.quantity;
		withoutTax += voucher.orderVoucherProduct.value * voucher.quantity;
	}

	return {
		deliveryPrice: 0, // TODO Calculate delivery price
		totalPrice: {
			priceLevel,
			withoutTax: roundToPlaces(withoutTax, 2),
			withTax,
		},
	};
}
