import { CreamColor } from "./CreamColor.js";
import type { OrderProductOptions } from "./OrderProductOptions.js";
import { ProductFigurineStatus } from "./ProductFigurineStatus.js";
import { ProductLabelStatus } from "./ProductLabelStatus.js";
import { ProductOption, type ProductOptionValueTypes } from "./ProductOption.js";
import { ProductPhotoStatus } from "./ProductPhotoStatus.js";
import { minFigurinePrice } from "./productOptionsConstants.js";

export type ProductOptionPrice<Option extends ProductOption> =
	| {
			type: "percentage";
			percent: number;
	  }
	| {
			type: "fixed";
			value: number;
	  }
	| {
			type: "per-value-precentage";
			percent(optionValue: Required<ProductOptionValueTypes>[Option]): number;
	  }
	| {
			type: "per-value-fixed";
			value(optionValue: Required<ProductOptionValueTypes>[Option]): number;
	  };

export const productOptionPrices: {
	[Option in ProductOption]: ProductOptionPrice<Option>;
} = {
	// If you change this, please update the minimalOptions object in this file.
	[ProductOption.Numbers]: {
		type: "per-value-fixed",
		value(numbers) {
			const pricePerDigit = 30;
			return numbers.reduce((sum, { number }) => sum + String(number).length * pricePerDigit, 0);
		},
	},
	// If you change this, please update the minimalOptions object in this file.
	[ProductOption.ExtraFruit]: {
		type: "percentage",
		percent: 10,
	},
	// If you change this, please update the minimalOptions object in this file.
	[ProductOption.GlutenFreeVariant]: {
		type: "percentage",
		percent: 10,
	},
	// If you change this, please update the minimalOptions object in this file.
	[ProductOption.LactoseFreeVariant]: {
		type: "percentage",
		percent: 20,
	},
	// If you change this, please update the minimalOptions object in this file.
	[ProductOption.Labels]: {
		type: "per-value-fixed",
		value(labels) {
			const pricePerNonEmptyLabel = 60;
			return labels.map((label) => label.text.trim()).filter(Boolean).length * pricePerNonEmptyLabel;
		},
	},
	// If you change this, please update the minimalOptions object in this file.
	[ProductOption.LyingPhoto]: {
		type: "percentage",
		percent: 20,
	},
	// If you change this, please update the minimalOptions object in this file.
	[ProductOption.StandingPhotos]: {
		type: "per-value-fixed",
		value(photos) {
			const pricePerPhoto = 100;
			return photos.length * pricePerPhoto;
		},
	},
	// If you change this, please update the minimalOptions object in this file.
	[ProductOption.Meringues]: {
		type: "percentage",
		percent: 5,
	},
	// If you change this, please update the minimalOptions object in this file.
	[ProductOption.CreamColor]: {
		type: "per-value-precentage",
		percent(color) {
			const percentagePerColor: Record<CreamColor, number> = {
				[CreamColor.Blue]: 10,
				[CreamColor.Green]: 10,
				[CreamColor.Red]: 10,
				[CreamColor.Pink]: 10,
				[CreamColor.Purple]: 10,
				[CreamColor.Gray]: 10,
				[CreamColor.Yellow]: 10,
				[CreamColor.White]: 5,
			};
			return percentagePerColor[color];
		},
	},
	// If you change this, please update the minimalOptions object in this file.
	[ProductOption.Macaroons]: {
		type: "per-value-fixed",
		value(macaroons) {
			const pricePerMacaroon = 42;
			return Object.values(macaroons).reduce((sum, value) => sum + value * pricePerMacaroon, 0);
		},
	},
	// If you change this, please update the minimalOptions object in this file.
	[ProductOption.Figurines]: {
		type: "per-value-fixed",
		value(figurines) {
			return figurines.reduce((sum, figurine) => sum + figurine.price, 0);
		},
	},
};

/**
 * This is used to calculate the lowest possible price to show to the user in the product configurator (ProductConfigurator.svelte).
 */
export const minimalOptions: Required<Omit<OrderProductOptions, "size">> = {
	lyingPhoto: { id: "1", file: { path: "foo.jpg", type: "image/jpg" }, status: ProductPhotoStatus.ToDo },
	standingPhotos: [{ id: "1", file: { path: "foo.jpg", type: "image/jpg" }, status: ProductPhotoStatus.ToDo }],
	labels: [{ id: "1", text: "1", status: ProductLabelStatus.ToDo }],
	extraFruit: true,
	glutenFree: true,
	lactoseFree: true,
	meringues: true,
	numbers: [{ id: "1", number: 1 }],
	creamColor: CreamColor.White,
	macaroons: {
		chocolate: 1,
		lemon: 0,
		pistachio: 0,
		raspberry: 0,
		saltedCaramel: 0,
	},
	figurines: [
		{ id: "1", price: minFigurinePrice, status: ProductFigurineStatus.ToDo, description: "Super action figurine" },
	],
};
